import { h, defineComponent, Transition } from 'vue'

export default defineComponent({
  name: 'TransitionFadeInComponent',
  functional: true,
  setup(_, { slots }) {
    return () =>
      h(Transition, {
        enterActiveClass: 'transition duration-[80ms] ease-out',
        enterFromClass: 'transform opacity-0',
        enterToClass: 'opacity-100',
        leaveActiveClass: 'transition duration-0',
        leaveFromClass: 'opacity-100',
        leaveToClass: 'transform opacity-0',
      }, slots.default)
  },
})
